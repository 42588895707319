.PhoneInput {
    height: 37px !important;
    background: #F5F6F7;
    border-radius: 4px;
}
.PhoneInputCountry {
   
    padding-left: 10px;
    padding-right: 10px;
    padding:10px;
}
.PhoneInputCountryIcon--border {
    box-shadow : none !important;
}

.PhoneInputCountrySelectArrow {
    display: none !important;
}
.PhoneInput input {
    background-color: transparent !important;
    border: none !important;
    height: 24px !important;
    font-size: 14px !important;
    margin-right:20px !important;
    color : #202A3E !important;
}

.PhoneInput input:focus {
 outline: none !important;
}

#chatloader circle:nth-of-type(1) {
    opacity: 0.35;
    animation: fade 0.7s cubic-bezier(0.39, 0.58, 0.57, 1) 0s infinite alternate-reverse;
  }
  #chatloader circle:nth-of-type(2) {
    opacity: 0.35;
    animation: fade 0.7s cubic-bezier(0.39, 0.58, 0.57, 1) 0.4s infinite alternate-reverse;
  }
  #chatloader circle:nth-of-type(3) {
    opacity: 0.35;
    animation: fade 0.7s cubic-bezier(0.39, 0.58, 0.57, 1) 0.8s infinite alternate-reverse;
  }
  #chatloader circle:nth-of-type(4) {
    -webkit-animation: expandBigger 0.5s infinite alternate 0.4s;
            animation: expandBigger 0.5s infinite alternate 0.4s;
  }
  #chatloader circle:nth-of-type(5) {
    -webkit-animation: expandTiny 0.5s infinite alternate;
            animation: expandTiny 0.5s infinite alternate;
  }
  #chatloader path {
    -webkit-animation: expandBiggest 1s linear infinite alternate;
            animation: expandBiggest 1s linear infinite alternate;
  }
  
  @-webkit-keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.45;
    }
  }
  
  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.45;
    }
  }
  @-webkit-keyframes expandTiny {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      -webkit-transform: translate(4.1px, -7.48px) scale(1.1);
              transform: translate(4.1px, -7.48px) scale(1.1);
    }
  }
  @keyframes expandTiny {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      -webkit-transform: translate(4.1px, -7.48px) scale(1.1);
              transform: translate(4.1px, -7.48px) scale(1.1);
    }
  }
  @-webkit-keyframes expandBigger {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      -webkit-transform: translate(2.52px, -6.26px) scale(1.1);
              transform: translate(2.52px, -6.26px) scale(1.1);
    }
  }
  @keyframes expandBigger {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      -webkit-transform: translate(2.52px, -6.26px) scale(1.1);
              transform: translate(2.52px, -6.26px) scale(1.1);
    }
  }
  @-webkit-keyframes expandBiggest {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      -webkit-transform: translate(-0.525px, -0.9487500191px) scale(1.025);
              transform: translate(-0.525px, -0.9487500191px) scale(1.025);
    }
  }
  @keyframes expandBiggest {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      -webkit-transform: translate(-0.525px, -0.9487500191px) scale(1.025);
              transform: translate(-0.525px, -0.9487500191px) scale(1.025);
    }
  }
  
  textarea:focus{
    /*border-color: gray;*/
    outline:none;
}
input:focus{
  /*border-color: gray;*/
  outline:none;
}

.mediachild-enter {
  opacity: 0;
  transform: scale(0.9);
}
.mediachild-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.mediachild-exit {
  opacity: 1;
}
.mediachild-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.skillgap-analysis-button-interest {
  transition: all 0.5s;
  box-shadow: 1px 1px 1px;
}
.skillgap-analysis-button-interest:hover{
  background-color: #a9b4f3;
  color: #ffffff ;
  box-shadow: 1px 1px 1px;
}
.skillgap-analysis-button-skills {
  transition: all 0.5s;
  box-shadow: 1px 1px 1px;
}
.skillgap-analysis-button-skills:hover{
  background-color: #c4f0d8;
  color: #ffffff ;
  box-shadow: 1px 1px 1px;
}
.skillgap-analysis-button-style {
  transition: all 0.5s;
  box-shadow: 1px 1px 1px;
}
.skillgap-analysis-button-style:hover{
  background-color:#eed5af;
  color: #ffffff ;
  box-shadow: 1px 1px 1px;
}
.linearprogress-skillmap > * {
  background-color: #53D28E !important;
}